import { useEffect } from 'react';
import { AppProps } from 'next/app';
import NextNProgress from 'nextjs-progressbar';
import { SWRConfig } from 'swr';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { arbitrum, bsc, fantom, mainnet, optimism, polygon, pulsechain } from 'wagmi/chains';
import { infuraProvider } from 'wagmi/providers/infura';
import { publicProvider } from 'wagmi/providers/public';
import { moneyTheme } from '@/components/authModal/raindbowKitTheme';
import { AlertModalProvider } from '@/components/common/CreateAlertModal/context';
import { AddWalletModalProvider } from '@/components/manageWallets/context/AddWalletContext';
import { CookieScript } from '@/components/vendorScripts/CookieScript';
import { GTag } from '@/components/vendorScripts/GTag';
import { Hotjar } from '@/components/vendorScripts/Hotjar';
import { Tapfiliate } from '@/components/vendorScripts/Tapfiliate';
import { FormResetProvider } from '@/helpers/hooks/formResetContext';
import useAnalyticsStore from '@/stores/analyticsStore';
import useCoinInfoStore from '@/stores/coinInfoStore';
import { EnvInfura } from '@/utils/env/infura';
import AnalyticsTracker from '@/utils/tracking';
import { getApolloClient } from '@/wordpress/utils/apolloClient';
import { ApolloProvider } from '@apollo/client/react';
import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { NotificationProvider } from '@web3uikit/core';
import { Constants } from '../helpers';
import '@rainbow-me/rainbowkit/styles.css';
import '../styles/scss/global.scss';
import '../styles/styles.css';
import '@/wordpress/styles/post/styles.css';

const { chains, publicClient } = configureChains(
  [mainnet, bsc, polygon, arbitrum, fantom, optimism, pulsechain],
  [infuraProvider({ apiKey: EnvInfura.publicKey }), publicProvider()],
);

const { connectors } = getDefaultWallets({
  appName: 'Moralis',
  projectId: '4fa7ec8bb2b9c02b0cc6469545448623',
  chains,
});

const config = createConfig({
  publicClient,
  autoConnect: true,
  connectors,
});

const Dapp = ({ Component, pageProps }: AppProps) => {
  const setTracker = useAnalyticsStore((state) => {
    return state.setTracker;
  });
  const isFetching = useCoinInfoStore((state) => {
    return state.isFetching;
  });

  useEffect(() => {
    const newTracker = new AnalyticsTracker();
    setTracker(newTracker);
  }, []);

  useEffect(() => {
    if (isFetching) {
      document.body.classList.add('fetching');
    } else {
      document.body.classList.remove('fetching');
    }
  }, [isFetching]);

  return (
    <>
      <ApolloProvider client={getApolloClient()}>
        <NextNProgress />
        <WagmiConfig config={config}>
          <RainbowKitProvider chains={chains} theme={moneyTheme}>
            <NotificationProvider>
              <SWRConfig value={Constants.swrCacheSettings}>
                <AlertModalProvider>
                  <FormResetProvider>
                    <AddWalletModalProvider>
                      <Component {...pageProps} />
                    </AddWalletModalProvider>
                  </FormResetProvider>
                </AlertModalProvider>
              </SWRConfig>
            </NotificationProvider>
          </RainbowKitProvider>
        </WagmiConfig>
      </ApolloProvider>

      <Tapfiliate />
      <CookieScript />
      <Hotjar />
      <GTag />
    </>
  );
};

export default Dapp;
