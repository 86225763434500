import color from '@/styles/color';
import { EFilterOperator, TFormOperator } from '@/types/filter.types';
import { TInterval } from '@/types/tokenExplorer';
import { CryptoLogoProps } from '@web3uikit/core';
import { utils } from '.';

export const notificationTypeEmail = 'email';
export const notificationTypeWebhook = 'webhook';
export const notificationTypeTelegram = 'telegram';
export const editTokenSplitter = '_mm-edit_';
export type TRouteValues = `${ERoutePath}`;
export type TRouteKeys = keyof typeof ERoutePath;
export enum ERoutePath {
  account = '/account',
  alerts = '/alerts',
  anonymous = '/anonymous',
  beans = '/profile/beans',
  blog = '/blog',
  bundles = '/bundles',
  bunny = '/bunny',
  chain = '/chain',
  community = '/community',
  detail = '/profile/nfts/detail',
  ebook = '/ebook',
  faqs = '/faqs',
  glossary = '/glossary',
  history = '/profile/history',
  home = '/',
  learn = '/learn',
  live = '/live',
  login = '/login',
  manageSubscription = '/manage-subscription',
  manageWallets = '/portfolio/manage/wallets',
  newCoins = '/new-coins',
  nftDetails = '/details',
  nfts = '/profile/nfts',
  notifications = '/notifications',
  portfolio = '/portfolio',
  pricing = '/pricing',
  privacy = '/privacy',
  profile = '/profile',
  promo = '/promo',
  savedQueries = '/profile/strategies/saved',
  savedTokens = '/profile/tokens/saved',
  search = '/search',
  saved = '/saved',
  searchNfts = '/search/nfts',
  enhancedTokenPage = '/enhanced-token-page',
  swap = '/swap',
  terms = '/terms',
  tokenExplorer = '/token-explorer',
  tokenSingle = '/token',
  tokens = '/profile/tokens',
  topTokensMarketCap = '/top-tokens-by-market-cap',
  trade = '/trade',
  unsubscribeEmails = '/unsubscribe-emails',
  topTokensMove = '/top-tokens-on-the-move',
  promoSurprise = '/surprise',
}
export enum EAccountSectionIds {
  beans = 'beans-transactions',
  general = 'account-general',
  learn = 'learn',
  rating = 'rating-history',
  subscription = 'subscription',
}
export const errorFeedback = 'Please try again or contact our amazing support team';

export const getCryptoLogoProps = (chain?: string): CryptoLogoProps => {
  switch (chain?.toLocaleLowerCase()) {
    case 'polygon':
      return { chain: 'polygon', bgColor: color.purple50 };
    case 'arbitrum':
      return { chain: 'arbitrum', bgColor: color.blue40 };
    case 'fantom':
      return { chain: 'fantom', bgColor: color.moneyBlueNatural40 };
    case 'avalanche':
      return { chain: 'avalanche', bgColor: '#DE0000' };
    case 'bsc':
    case 'binance':
      return { chain: 'binance', bgColor: color.yellow50 };
    case 'cronos':
      return { chain: 'cronos', bgColor: color.blue70 };
    case 'coinbase':
      return { chain: 'coinbase', bgColor: color.blueGray50 };
    case 'ronin':
      return { chain: 'ronin', bgColor: color.red60 };
    case 'optimism':
      return { chain: 'optimism', bgColor: color.red30 };
    case 'ethereum':
      return { chain: 'ethereum', bgColor: color.moneyBlue50 };
    case 'pulse':
    case 'pulsechain':
      return {
        chain: 'pulsechain',
        bgColor:
          'linear-gradient(211.94deg, rgb(0, 234, 255) 14.03%, rgb(0, 128, 255) 32.21%, rgb(128, 0, 255) 50.38%, rgb(230, 25, 230) 68.56%, rgb(255, 0, 0) 86.74%), rgb(255, 255, 255)',
      };
    case 'base':
      return { chain: 'base', bgColor: '#0052FF' } as unknown as CryptoLogoProps; // @note - old web3uikit types are being used here and this needs to be removed or moved to MUI
    case 'linea':
      return { chain: 'linea', bgColor: '#121212' } as unknown as CryptoLogoProps; // @note - old web3uikit types are being used here and this needs to be removed or moved to MUI
    case 'solana':
      return { chain: 'solana', bgColor: '#7870C6' } as unknown as CryptoLogoProps; // @note - old web3uikit types are being used here and this needs to be removed or moved to MUI
    default:
      return { chain: 'ethereum', bgColor: color.moneyBlue50 };
  }
};

// Additional function to display images in bubbles - when adding new chains need to add an image here too
export function getChainIconUrl(chainId: string) {
  switch (chainId) {
    case CHAIN_IDS.arbitrum:
      return '/static/chains/arbitrum.svg';
    case CHAIN_IDS.avalanche:
      return '/static/chains/avalanche.svg';
    case CHAIN_IDS.binance:
      return '/static/chains/binance.svg';
    case CHAIN_IDS.fantom:
      return '/static/chains/fantom.svg';
    case CHAIN_IDS.polygon:
      return '/static/chains/polygon.svg';
    case CHAIN_IDS.pulse:
      return '/static/chains/pulse.svg';
    case CHAIN_IDS.optimism:
      return '/static/chains/optimism.svg';
    case CHAIN_IDS.base:
      return '/static/chains/base.svg';
    case CHAIN_IDS.linea:
      return '/static/chains/linea.svg';
    case CHAIN_IDS.solana:
      return '/static/chains/solana.svg';
    case CHAIN_IDS.ethereum:
    default:
      return '/static/chains/ethereum.svg';
  }
}

export const chainNameToChainIdMapping = (chainName?: string) => {
  switch (chainName) {
    case 'ethereum':
    case 'eth':
      return CHAIN_IDS.ethereum;
    case 'bsc':
    case 'binance':
      return CHAIN_IDS.binance;
    case 'polygon':
      return CHAIN_IDS.polygon;
    case 'arbitrum':
      return CHAIN_IDS.arbitrum;
    case 'avalanche':
      return CHAIN_IDS.avalanche;
    case 'fantom':
      return CHAIN_IDS.fantom;
    case 'cronos':
      return CHAIN_IDS.cronos;
    case 'optimism':
      return CHAIN_IDS.optimism;
    case 'pulsechain':
    case 'pulse':
      return CHAIN_IDS.pulse;
    case 'base':
      return CHAIN_IDS.base;
    case 'linea':
      return CHAIN_IDS.linea;
    case 'solana':
      return CHAIN_IDS.solana;
    default:
      return undefined;
  }
};

export const CHAIN_IDS = {
  solana: 'solana',
  ethereum: '0x1',
  binance: '0x38',
  base: '0x2105',
  arbitrum: '0xa4b1',
  polygon: '0x89',
  avalanche: '0xa86a',
  optimism: '0xa',
  linea: '0xe708',
  fantom: '0xfa',
  pulse: '0x171',
  cronos: '0x19',
};

export const chainIdToChainNameMappingDex = (chainId: string) => {
  switch (chainId) {
    case CHAIN_IDS.ethereum:
      return 'ethereum';
    case CHAIN_IDS.binance:
      return 'bsc';
    case CHAIN_IDS.polygon:
      return 'polygon';
    case CHAIN_IDS.arbitrum:
      return 'arbitrum';
    case CHAIN_IDS.avalanche:
      return 'avalanche';
    case CHAIN_IDS.fantom:
      return 'fantom';
    case CHAIN_IDS.cronos:
      return 'cronos';
    case CHAIN_IDS.optimism:
      return 'optimism';
    case CHAIN_IDS.pulse:
      return 'pulse';
    case CHAIN_IDS.base:
      return 'base';
    case CHAIN_IDS.linea:
      return 'linea';
    case CHAIN_IDS.solana:
      return 'solana';
    default:
      return 'Unknown';
  }
};

export const chainIdToChainNameMappingDexScreener = (chainId: string) => {
  switch (chainId) {
    case CHAIN_IDS.ethereum:
      return 'ethereum';
    case CHAIN_IDS.binance:
      return 'bsc';
    case CHAIN_IDS.polygon:
      return 'polygon';
    case CHAIN_IDS.arbitrum:
      return 'arbitrum';
    case CHAIN_IDS.avalanche:
      return 'avalanche';
    case CHAIN_IDS.fantom:
      return 'fantom';
    case CHAIN_IDS.cronos:
      return 'cronos';
    case CHAIN_IDS.optimism:
      return 'optimism';
    case CHAIN_IDS.pulse:
      return 'pulsechain';
    case CHAIN_IDS.base:
      return 'base';
    case CHAIN_IDS.linea:
      return 'linea';
    case CHAIN_IDS.solana:
      return 'solana';
    default:
      return 'Unknown';
  }
};

export const chainIdToChainNameMapping = (chainId?: string) => {
  switch (chainId) {
    case CHAIN_IDS.ethereum:
      return 'ethereum';
    case CHAIN_IDS.binance:
      return 'bsc';
    case CHAIN_IDS.polygon:
      return 'polygon';
    case CHAIN_IDS.arbitrum:
      return 'arbitrum';
    case CHAIN_IDS.avalanche:
      return 'avalanche';
    case CHAIN_IDS.fantom:
      return 'fantom';
    case CHAIN_IDS.cronos:
      return 'cronos';
    case CHAIN_IDS.optimism:
      return 'optimism';
    case CHAIN_IDS.pulse:
      return 'pulsechain';
    case CHAIN_IDS.base:
      return 'base';
    case CHAIN_IDS.linea:
      return 'linea';
    case CHAIN_IDS.solana:
      return 'solana';
    // TODO: (Maxim) return null unstead
    default:
      return 'unknown';
  }
};

//  TODO: (Maxim) remove this function and use chainIdToChainNameMapping instead
export const chainIdToChainNameMappingForTokenPage = (chainId?: string) => {
  const chainName = chainIdToChainNameMapping(chainId);
  if (chainName == 'bsc') return 'binance';
  else if (chainName == 'pulsechain') return 'pulse';
  return chainName;
};

export const beanTransactionTitleMapping = (titleNum: number) => {
  switch (titleNum) {
    case 1:
      return 'Daily Beans Collection';
    case 2:
      return 'Monthly Pro Beans';
    case 3:
      return 'Yearly Pro Beans';
    case 4:
      return 'By Moralis Team';
    case 5:
      return 'Beans Spent';
    case 6:
      return 'Trading Beans Reward';
    default:
      return 'N/A';
  }
};

export const supportedChains = ['1', '56', '137'];

export const moralisSocials = {
  twitter: 'https://twitter.com/moraliscom',
};

export const moralisLinks = {
  affiliate: 'https://affiliate.moralismoney.com/',
  devsWanted:
    'https://talent.moralis.io/jobs/1995445-backend-engineer-blockchain-data/7d24707f-1854-47f2-8162-1bafb053fa9a',
  discord: 'https://discord.gg/WPGzq2brKB',
  docs: 'https://docs.moralis.io/',
  enterpriseForm: 'https://moralis.typeform.com/to/G7Q7xe7R',
  faq: 'https://github.com/MoralisWeb3/moralis-money-faq/blob/main/README.md#-welcome-to-moralis-money-faq',
  helpCenter: 'https://money-help.moralis.io/en/',
  howFiltersWork: 'https://github.com/MoralisWeb3/moralis-money-faq/blob/main/README.md#how-do-filters-work',
  legal: 'https://drive.google.com/drive/u/1/folders/1zVV07zI4GU20_Jp2pt2wIwaRQrDc0P3c',
  mailTo: 'money@moralis.io',
  moneyLineDocs:
    'https://money-help.moralis.io/en/articles/108369-how-to-find-the-money-line-indicator-on-my-tradingview-account-how-to-set-up-alerts',
  moralisMoneyGetStarted:
    'https://start.moralismoney.com/optin3s4xdq9i?utm_source=moralismoney&utm_campaign=get-tutorial&utm_medium=card',
  moralisMoneyHome: 'https://moralis.com/',
  readme_GettingStarted:
    'https://github.com/MoralisWeb3/moralis-money-faq/blob/main/affiliates/welcome%20and%20faq.md#-how-to-get-started',
  readme_HowToUse: 'https://github.com/MoralisWeb3/moralis-money-faq/blob/main/README.md#using-moralis-money',
  readme_Value10x:
    'https://github.com/MoralisWeb3/moralis-money-faq/blob/main/README.md#-why-will-moralis-money-10x-in-value-over-the-coming-months',
  requestFeatureForm: 'https://moralis.typeform.com/to/wLtl249j',
  startMoralisMoney: 'start.moralismoney.com',
  support: 'https://money-help.moralis.io',
  supportLinkText: 'Moralis support center',
  terms: '#',
  verified: 'https://github.com/MoralisWeb3/moralis-money-faq/blob/main/verified.md#background',
  verify: 'https://github.com/MoralisWeb3/moralis-money-faq/blob/main/verified.md#how-to-get-your-coin-verified',
  publicAPIDocs: 'https://developers.moralis.com/?utm_source=moralis-money&utm_medium=mm-sidebar',
};

export const externalLinks = {
  metamask: 'https://metamask.io/download',
  dexToolsTutorial: 'https://dextools.medium.com/comments-and-tips-about-dext-score-7f6cfd628ee2',
};

export const emailAddress = {
  support: 'money@moralis.io',
} as const;

// Global swr cache settings
export const swrCacheSettings = {
  refreshInterval: 300000,
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  keepPreviousData: true,
};

export type FilterTimeFrame = {
  id: string;
  isFreePlan: boolean;
  label: string;
};

export type TTransform = {
  input: (value: string | number, unit?: number) => number;
  output: (value: string | number, unit?: number) => number;
};

export type TOperator = { id: string; label: string };
export type TOperatorPrefix = TOperator & { prefix: React.ReactNode };

export type TTokenExplorerFilters = {
  buyerTimeFrame?: TInterval;
  buyersVolumeTimeFrame?: TInterval;
  dextScoreTimeFrame?: TInterval;
  excludedOperators: TFormOperator[];
  expBuyerTimeFrame?: TInterval;
  expNetBuyerTimeFrame?: TInterval;
  expSellerTimeFrame?: TInterval;
  format: (value: number) => string;
  holderTimeFrame?: TInterval;
  isFreeFilter: boolean;
  key: string;
  label: string;
  liquidityTimeFrame?: TInterval;
  netBuyerTimeFrame?: TInterval;
  netVolumeTimeFrame?: TInterval;
  operatorLabel: string;
  operators: TOperator[];
  pricePercentTimeFrame?: TInterval;
  range?: TTokenExplorerFilterRange;
  sellerTimeFrame?: TInterval;
  sellersVolumeTimeFrame?: TInterval;
  timeFrame?: FilterTimeFrame[];
  transformUnits?: TTransform;
  volumeTimeFrame?: TInterval;
};

type TTokenExplorerFilterRange = {
  max: number;
  min: number;
};

/**
 * Token explorer filters common time frame
 */
export const filtersTimeFrame = [
  {
    id: 'OneQuarter',
    label: 'This Quarter',
    isFreePlan: true,
    isStarterPlan: true,
  },
  {
    id: 'OneMonth',
    label: 'This Month',
    isFreePlan: true,
    isStarterPlan: true,
  },
  {
    id: 'ThreeWeeks',
    label: 'Last 3 Weeks',
    isFreePlan: false,
    isStarterPlan: true,
  },
  {
    id: 'TwoWeeks',
    label: 'Last 2 Weeks',
    isFreePlan: false,
    isStarterPlan: true,
  },
  {
    id: 'OneWeek',
    label: 'This Week',
    isFreePlan: false,
    isStarterPlan: true,
  },
  {
    id: 'FourDays',
    label: 'Last 4 Days',
    isFreePlan: false,
    isStarterPlan: true,
  },
  {
    id: 'TwoDays',
    label: 'Last 2 Days',
    isFreePlan: false,
    isStarterPlan: true,
  },
  {
    id: 'OneDay',
    label: 'This Day',
    isFreePlan: false,
    isStarterPlan: true,
  },
  {
    id: 'TwelveHours',
    label: 'Last 12 Hour',
    isFreePlan: false,
    isStarterPlan: false,
  },
  {
    id: 'FourHours',
    label: 'Last 4 Hour',
    isFreePlan: false,
    isStarterPlan: false,
  },
  {
    id: 'OneHour',
    label: 'This Hour',
    isFreePlan: false,
    isStarterPlan: false,
  },
  {
    id: 'ThirtyMinutes',
    label: 'Last 30 Minutes',
    isFreePlan: false,
    isStarterPlan: false,
  },
  {
    id: 'TenMinutes',
    label: 'Last 10 Minutes',
    isFreePlan: false,
    isStarterPlan: false,
  },
];

const operatorFilters = [
  { id: EFilterOperator.GT, label: 'Increased by at least' },
  { id: EFilterOperator.LT, label: 'Decreased by at least' },
];

const generalOperators = [
  { id: EFilterOperator.LT, label: 'Less than' },
  { id: EFilterOperator.GT, label: 'Greater than' },
];

/**
 * Token Explorer filters mapping from key to label and format
 */
export const TokenExplorerFiltersMapping: Record<string, TTokenExplorerFilters> = {
  daysSinceMinted: {
    excludedOperators: [],
    format: (value: number) => {
      return utils.format.formattedNumber(value);
    },
    isFreeFilter: true,
    key: 'daysSinceMinted',
    label: 'Coin Age',
    operatorLabel: 'Enter a value in days...',
    operators: generalOperators,
  },
  DextScore: {
    excludedOperators: [],
    format: (value: number) => {
      return utils.format.formattedNumber(value);
    },
    isFreeFilter: true,
    key: 'DextScore',
    label: 'Security Score',
    operatorLabel: 'Enter a number between 1 and 99...',
    range: { min: 1, max: 99 },
    operators: generalOperators,
  },
  FullyDilutedValuation: {
    excludedOperators: [],
    format: (value: number) => {
      return utils.format.formattedNumber(value);
    },
    isFreeFilter: true,
    key: 'FullyDilutedValuation',
    label: 'Fully Diluted Valuation',
    operatorLabel: 'Enter a number',
    operators: generalOperators,
    transformUnits: {
      input: (value, unit = 1_000_000): number => {
        const parsedNum = Number(value);
        if (Number.isNaN(parsedNum)) return 0;
        return parsedNum / unit;
      },
      output: (value, unit = 1_000_000): number => {
        const parsedNum = Number(value);
        if (Number.isNaN(parsedNum)) return 0;
        return parsedNum * unit;
      },
    },
    timeFrame: [
      { id: 'thousand', label: 'Thousand', isFreePlan: true },
      { id: 'million', label: 'Million', isFreePlan: true },
    ],
  },
  MarketCap: {
    excludedOperators: [],
    format: (value: number) => {
      return utils.format.formattedNumber(value);
    },
    isFreeFilter: true,
    key: 'MarketCap',
    label: 'Market Cap',
    operatorLabel: 'Enter a number',
    operators: generalOperators,
    transformUnits: {
      input: (value, unit = 1_000_000): number => {
        const parsedNum = Number(value);
        if (Number.isNaN(parsedNum)) return 0;
        return parsedNum / unit;
      },
      output: (value, unit = 1_000_000): number => {
        const parsedNum = Number(value);
        if (Number.isNaN(parsedNum)) return 0;
        return parsedNum * unit;
      },
    },
    timeFrame: [
      { id: 'thousand', label: 'Thousand', isFreePlan: true },
      { id: 'million', label: 'Million', isFreePlan: true },
    ],
  },
  LiquidityChangeUSD: {
    key: 'LiquidityChangeUSD',
    operatorLabel: 'Enter a value in USD...',
    operators: operatorFilters,
    label: 'Liquidity',
    format: (value: number) => {
      return utils.format.formattedNumber(value);
    },
    excludedOperators: [],
    liquidityTimeFrame: 'OneWeek',
    isFreeFilter: true,
    timeFrame: filtersTimeFrame,
  },

  HoldersChange: {
    key: 'HoldersChange',
    operatorLabel: 'Enter a value...',
    operators: operatorFilters,
    label: 'Holders',
    format: (value: number) => {
      return utils.format.formattedNumber(value);
    },
    excludedOperators: [],
    holderTimeFrame: 'OneWeek',
    isFreeFilter: true,
    timeFrame: filtersTimeFrame,
  },

  BuyersChange: {
    key: 'BuyersChange',

    operatorLabel: 'Enter number of buyers...',
    operators: operatorFilters,
    label: 'Buyers',
    format: (value: number) => {
      return utils.format.formattedNumber(value);
    },
    excludedOperators: [],
    buyerTimeFrame: 'OneWeek',
    isFreeFilter: true,
    timeFrame: filtersTimeFrame,
  },

  ExperiencedBuyersChange: {
    key: 'ExperiencedBuyersChange',
    operatorLabel: 'Enter number of buyers...',
    operators: operatorFilters,
    label: 'Experienced Buyers',
    format: (value: number) => {
      return utils.format.formattedNumber(value);
    },
    excludedOperators: [],
    expBuyerTimeFrame: 'OneWeek',
    isFreeFilter: true,
    timeFrame: filtersTimeFrame,
  },
  SellersChange: {
    key: 'SellersChange',
    operatorLabel: 'Enter number of sellers...',
    operators: operatorFilters,
    label: 'Sellers',
    format: (value: number) => {
      return utils.format.formattedNumber(value);
    },
    excludedOperators: [],
    sellerTimeFrame: 'OneWeek',
    isFreeFilter: true,
    timeFrame: filtersTimeFrame,
  },
  ExperiencedSellersChange: {
    key: 'ExperiencedSellersChange',
    operatorLabel: 'Enter number of sellers...',
    operators: operatorFilters,
    label: 'Experienced Sellers',
    format: (value: number) => {
      return utils.format.formattedNumber(value);
    },
    excludedOperators: [],
    expSellerTimeFrame: 'OneWeek',
    isFreeFilter: true,
    timeFrame: filtersTimeFrame,
  },
  NetBuyersChange: {
    key: 'NetBuyersChange',
    operatorLabel: 'Enter number of buyers...',
    operators: operatorFilters,
    label: 'Net Buyers',
    format: (value: number) => {
      return utils.format.formattedNumber(value);
    },
    excludedOperators: [],
    netBuyerTimeFrame: 'OneWeek',
    isFreeFilter: true,
    timeFrame: filtersTimeFrame,
  },
  ExperiencedNetBuyersChange: {
    key: 'ExperiencedNetBuyersChange',
    operatorLabel: 'Enter number of buyers...',
    operators: operatorFilters,
    label: 'Experienced Net Buyers',
    format: (value: number) => {
      return utils.format.formattedNumber(value);
    },
    excludedOperators: [],
    expNetBuyerTimeFrame: 'OneWeek',
    isFreeFilter: true,
    timeFrame: filtersTimeFrame,
  },
  TwitterFollower: {
    excludedOperators: [],
    format: (value: number) => {
      return utils.format.formattedNumber(value);
    },
    isFreeFilter: true,
    key: 'TwitterFollower',
    label: 'Social Media Metrics',
    operatorLabel: 'Enter a number...',
    operators: generalOperators,
  },
  PricePercentChangeUSD: {
    key: 'PricePercentChangeUSD',
    operatorLabel: 'Enter price in percent...',
    operators: operatorFilters,
    label: 'Price Percent',
    format: (value: number) => {
      return utils.format.formattedNumber(value);
    },
    excludedOperators: [],
    pricePercentTimeFrame: 'OneWeek',
    isFreeFilter: true,
    timeFrame: filtersTimeFrame,
  },
  VolumeChangeUSD: {
    key: 'VolumeChangeUSD',
    operatorLabel: 'Enter volume...',
    label: 'Total Volume',
    format: (value: number) => {
      return utils.format.formattedNumber(value);
    },
    excludedOperators: [],
    volumeTimeFrame: 'OneWeek',
    isFreeFilter: true,
    timeFrame: filtersTimeFrame,
    operators: generalOperators,
  },
  BuyersVolumeChangeUSD: {
    key: 'BuyersVolumeChangeUSD',
    operatorLabel: 'Enter buyers volume...',
    operators: generalOperators,
    label: 'Buyers Volume',
    format: (value: number) => {
      return utils.format.formattedNumber(value);
    },
    excludedOperators: [],
    buyersVolumeTimeFrame: 'OneWeek',
    isFreeFilter: true,
    timeFrame: filtersTimeFrame,
  },
  SellersVolumeChangeUSD: {
    key: 'SellersVolumeChangeUSD',
    operatorLabel: 'Enter sellers volume...',
    operators: generalOperators,
    label: 'Sellers Volume',
    format: (value: number) => {
      return utils.format.formattedNumber(value);
    },
    excludedOperators: [],
    sellersVolumeTimeFrame: 'OneWeek',
    isFreeFilter: true,
    timeFrame: filtersTimeFrame,
  },
  NetVolumeChangeUSD: {
    key: 'NetVolumeChangeUSD',
    operatorLabel: 'Enter Buy Pressure...',
    operators: generalOperators,
    label: 'Buy Pressure',
    format: (value: number) => {
      return utils.format.formattedNumber(value);
    },
    excludedOperators: [],
    netVolumeTimeFrame: 'OneWeek',
    isFreeFilter: true,
    timeFrame: filtersTimeFrame,
  },
};

// ToDo: merge with the old implementation if nothing breaks
export const TokenExplorerFiltersMappingNewFilters: Record<string, TTokenExplorerFilters> = {
  onChainStrengthIndex: {
    excludedOperators: [],
    format: (value: number) => {
      return utils.format.formattedNumber(value);
    },
    isFreeFilter: true,
    key: 'onChainStrengthIndex',
    label: 'On-Chain Strength Index',
    operatorLabel: '',
    operators: generalOperators,
  },
  moralisScore: {
    excludedOperators: [],
    format: (value: number) => {
      return utils.format.formattedNumber(value);
    },
    isFreeFilter: true,
    key: 'moralisScore',
    label: 'Moralis Score',
    operatorLabel: '',
    operators: generalOperators,
  },
  coinCategories: {
    excludedOperators: [],
    format: (value: number) => {
      return utils.format.formattedNumber(value);
    },
    isFreeFilter: true,
    key: 'coinCategories',
    label: 'Coin Categories',
    operatorLabel: '',
    operators: generalOperators,
  },
};

/**
 * Array of Token Explorer filters
 */
export const TokenExplorerFilters = Object.values(TokenExplorerFiltersMapping);

/**
 * Array of the free Token Explorer filters
 */
export const freeFilters = TokenExplorerFilters.filter((f) => {
  return f.isFreeFilter;
});
/**
 * Array of the paid Token Explorer filters
 */
export const paidFilters = TokenExplorerFilters.filter((f) => {
  return !f.isFreeFilter;
});

/**
   * Array of the Token Explorer filters which have paid time frames
   *
   * example returned item:
   [
        {
          "key": "LiquidityChangeUSD",
          "timeFrame": [
              "OneHour",
              "OneDay",
          ]
        },
        ...other array items
    ]
   */
export const paidFilterTimeFrames = TokenExplorerFilters.filter((f) => {
  return f.timeFrame;
}).map((filtersWithTimeFrames) => {
  return {
    key: filtersWithTimeFrames.key,
    timeFrame: filtersWithTimeFrames.timeFrame
      ?.map((timePeriod) => {
        if (!timePeriod.isFreePlan) {
          return timePeriod.id;
        }
      })
      .filter((item) => {
        return item;
      }),
  };
});

export const starterPlanTimeFrames = filtersTimeFrame.filter((time) => {
  return time.isStarterPlan;
});

export const COIN_AGE_DAYS_BEFORE_ON_FREE_PLAN = 7;

// filters that can have negative values
export const filtersNamesWithNegativeValues = [
  'pricePercentChangeUSD',
  'netVolumeChangeUSD',
  'experiencedNetBuyersChange',
  'liquidityChangeUSD',
  'netBuyersChange',
].map((f) => f.toLowerCase());

export const filterWithDecreasedByOperator = Object.entries(TokenExplorerFiltersMapping)
  .map((filter) => {
    if (filter[1].operators.find((op) => op.label.includes('Decreased'))) {
      return filter[0]?.toLowerCase();
    }
  })
  .filter((f) => f);

export const minAmountOfBeansForReview = 2000;

export enum SwappedFrom {
  SWAP_MENU_ABSTRACT = 'swapMenu_abstract',
  SWAP_MENU_CLASSIC = 'swapMenu_classic',
  TOKEN_PAGE = 'tokenPage',
}
