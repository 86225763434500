import { FormProvider } from 'react-hook-form';
import { AlertsUsedLabel } from '@/components/common/AlertsUsedLabel';
import { ButtonPrimary } from '@/components/core/Button';
import { useNotify } from '@/helpers/hooks';
import { useAlertModal } from '@/helpers/hooks/useAlertModal';
import { useBundles } from '@/helpers/hooks/useBundles';
import { defaultUseFormErrorHandler, useFormHandler } from '@/helpers/hooks/useFormHandler';
import { useMagic } from '@/helpers/hooks/useMagic';
import { useTelegram } from '@/helpers/hooks/useTelegram';
import { useUserAlertsCount } from '@/helpers/hooks/useUserAlertsCount';
import { GetCuratedWalletListResponse } from '@/services/index';
import { AlertScenarioTextVisualization } from '../../atoms';
import { BundleField, NotificationsFieldset } from '../../molecules';
import { IBundleAlertFormData, IBundleAlertProps } from './types';
import { getFormDefaultValues, getPayload } from './utils';
import { language } from './values';
import styles from './styles.module.scss';

const BundleAlert = ({ bundle: bundleInitial }: IBundleAlertProps) => {
  const { isLimitReached } = useUserAlertsCount();
  const { close: closeModal } = useAlertModal();
  const { bundles, followBundle, messages } = useBundles();
  const { validateTelegram } = useTelegram();
  const { errorNotification, successNotification } = useNotify();
  const { validateEmail } = useMagic();

  const { isLoading, handleOnSubmit, ...formDefaultMethods } = useFormHandler<
    IBundleAlertFormData,
    GetCuratedWalletListResponse
  >({
    defaultValues: getFormDefaultValues(bundleInitial),
    onSubmit: async (formData) => {
      if (formData.toggleProtocolTelegram) {
        formData.inputProtocolTelegram = await validateTelegram(formData.inputProtocolTelegram);
      }
      if (formData.toggleProtocolEmail) {
        formData.inputProtocolEmail = await validateEmail(formData.inputProtocolEmail);
      }
      const payload = getPayload(formData);
      return followBundle(payload, {
        onSuccess: (r) => successNotification({ message: messages.follow(r.name) }),
      });
    },
    onSuccess: closeModal,
    onError: (error) => defaultUseFormErrorHandler(error, errorNotification),
  });

  const selectedBundle = bundles?.all?.find((bundle) => bundle?.id === formDefaultMethods.watch('selectBundle'));

  return (
    <FormProvider {...formDefaultMethods}>
      <form className={styles.form} onSubmit={handleOnSubmit}>
        <BundleField bundles={bundleInitial ? [bundleInitial] : bundles?.noAlert} />
        <NotificationsFieldset />
        <div className={styles.footer}>
          <AlertScenarioTextVisualization alertType="bundle" bundleName={selectedBundle?.name} />
          <AlertsUsedLabel />
          <ButtonPrimary
            className={styles.saveButton}
            disabled={isLimitReached}
            isLoading={isLoading}
            size="xl"
            type="submit"
          >
            {language.en.save}
          </ButtonPrimary>
        </div>
      </form>
    </FormProvider>
  );
};

export default BundleAlert;
