import { ApiError, GetTokenExplorerResponseDto, PutQueryRequestDto } from '@/services/index';
import useAuthStore from '@/stores/authStore';
import useQueryStore from '@/stores/queryStore';
import { api } from '../utils';
import { useNotify } from './useNotify';

const useTokenQueryAPI = () => {
  const authToken = useAuthStore((state) => {
    return state.authToken;
  });
  const setQueries = useQueryStore((state) => {
    return state.setQueries;
  });
  const updateQuery = useQueryStore((state) => {
    return state.updateQuery;
  });
  const { errorNotification, successNotification } = useNotify();

  const deleteSavedQuery = async (strategyId: string) => {
    if (!authToken) return;
    try {
      await api.user.userControllerDeleteStrategyAlert1(strategyId);
      successNotification({
        title: 'Deletion Successful',
        message: 'Strategy has been deleted',
      });
      return;
    } catch {
      errorNotification({
        title: 'Strategy was not deleted',
        message: 'We were unable to delete this saved strategy. Please try again',
      });
      return;
    }
  };

  const getSavedQueries = async () => {
    if (!authToken) return [] as GetTokenExplorerResponseDto[];
    try {
      const SavedQueries = await api.user.userControllerGetStrategy1();
      setQueries(SavedQueries);
      return SavedQueries;
    } catch {
      errorNotification({
        title: 'Oh dear',
        message: 'We were unable to get your saved filters.',
      });
      return [] as GetTokenExplorerResponseDto[];
    }
  };

  const updateSavedQuery = async (queryParams: PutQueryRequestDto, isDelete?: boolean) => {
    if (!authToken) return;
    try {
      const updateResult = await api.user.userControllerUpdateStrategyAlert1(queryParams);
      successNotification({
        title: `${queryParams.name} Updated`,
        message: isDelete
          ? `You will no longer receive email notifications`
          : `You have updated your query notifications`,
      });
      updateQuery(updateResult);
      return updateResult;
    } catch (error) {
      const err: ApiError = error as ApiError;
      // display custom message if webhook server is not reachable
      if (err.body.message === 'MMBE00089') {
        errorNotification({
          title: 'Unable to update strategy',
          message: err.body.cause,
        });
        return;
      }
      if (err.body.message === 'MMBE00090') {
        errorNotification({
          title: 'Unable to create strategy alert',
          message: err.body.cause,
        });
        return;
      }
      errorNotification({
        title: 'Unable to update strategy',
        message: 'Please try again or contact our amazing support team',
      });
      return;
    }
  };

  return { deleteSavedQuery, getSavedQueries, updateSavedQuery };
};

export default useTokenQueryAPI;
