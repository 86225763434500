import { useState } from 'react';
import { useRouter } from 'next/router';
import useSWR from 'swr';
import { CreateReviewRequestDto, UpdateReviewRequestDto } from '@/services/index';
import useAuthStore from '@/stores/authStore';
import useBeanStore from '@/stores/beanStore';
import { useAuthModal } from '@/stores/modalStore';
import { api, Constants } from '../utils';

const fetchAllUserReviews = async () => {
  return await api.user.userControllerGetUserReviews();
};

export const useUserReviews = () => {
  const { toggleAuthModal } = useAuthModal();
  const router = useRouter();
  const authToken = useAuthStore((state) => {
    return state.authToken;
  });
  const beans = useBeanStore((state) => {
    return state.beans;
  });
  const {
    data: userReviews,
    mutate: mutateUserReviews,
    isValidating: isUserReviewsValidating,
    isLoading: isUserReviewsLoading,
  } = useSWR('userReviews', fetchAllUserReviews, {
    revalidateOnFocus: false,
  });

  const [isBeanModalOpen, setIsBeanModalOpen] = useState(false);

  const addUserReview = async (request: CreateReviewRequestDto) => {
    await api.user.userControllerCreateReview(request);
    mutateUserReviews();
  };

  const updateUserReview = async (request: UpdateReviewRequestDto) => {
    await api.user.userControllerUpdateReview(request);
    mutateUserReviews();
  };

  const onUserRatingClick = async ({
    chainId,
    rating,
    address,
    reviewId,
    type,
    name,
    symbol,
  }: CreateReviewRequestDto & { reviewId?: string }) => {
    if (!authToken) {
      toggleAuthModal();
      return;
    }
    if (!beans.amount || beans.amount < Constants.minAmountOfBeansForReview) {
      setIsBeanModalOpen(true);
      return;
    }
    try {
      if (reviewId) {
        await updateUserReview({ rating, reviewId });
      } else {
        await addUserReview({
          chainId,
          rating,
          type,
          address,
          name,
          symbol,
        });
      }
      router.replace(router.asPath);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.body?.cause?.toLowerCase()?.includes('bean')) setIsBeanModalOpen(true);
    }
  };

  const toggleBeanModal = () => setIsBeanModalOpen((prev) => !prev);

  const setBeanModalVisible = (isOpen: boolean) => setIsBeanModalOpen(isOpen);

  return {
    userReviews,
    addUserReview,
    updateUserReview,
    onUserRatingClick,
    isBeanModalOpen,
    toggleBeanModal,
    setBeanModalVisible,
    isFetching: isUserReviewsLoading || isUserReviewsValidating,
  };
};
