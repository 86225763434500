/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';

const useOnClickOutside = (ref: any, handler: any) => {
  useEffect(() => {
    const listener = (event: any) => {
      if (
        !ref.current ||
        ref.current.contains(event.target) ||
        !ref.current.classList.contains('open') ||
        event.target.parentNode.classList.contains('avatar-holder') ||
        event.target.parentNode.classList.contains('avatar-image')
      ) {
        return;
      }
      handler(event);
    };

    const handleDocumentClick = (event: any) => {
      listener(event);
    };

    document.addEventListener('mousedown', handleDocumentClick);
    document.addEventListener('touchstart', handleDocumentClick);

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
      document.removeEventListener('touchstart', handleDocumentClick);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;
