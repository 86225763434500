import { Constants } from '@/helpers/utils';

export const language = {
  en: {
    titleBundle: 'Create Bundle Alert',
    titleChoose: 'Choose Alert Type',
    titleToken: 'Create Token Alert',
    titleStrategy: 'Create Strategy Alert',
  },
};

export type config = {
  email?: string;
  webhookUrl?: string;
};
export type FilterType = 'experiencedNetBuyersChange' | 'netBuyersChange' | 'netVolumeChangeUSD' | 'priceChange';
export type OperatorType = 'gt' | 'lt';
export type TimeframeType =
  | '10'
  | '30'
  | '60'
  | '240'
  | '720'
  | '1440'
  | '2880'
  | '5760'
  | '10080'
  | '20160'
  | '30240'
  | '43200'
  | '129600';
export type CooldownTimeFrameType = '0' | '5' | '30' | '60' | '300' | '3600' | '14400' | '86400';

export type AlertType = 'token' | 'strategy' | 'bundle';

export const percentages = [
  { label: '-100%', percentage: -100 },
  { label: '-75%', percentage: -75 },
  { label: '-50%', percentage: -50 },
  { label: '-25%', percentage: -25 },
  { label: '0%', percentage: 0 },
  { label: '25%', percentage: 25 },
  { label: '50%', percentage: 50 },
  { label: '75%', percentage: 75 },
  { label: '100%', percentage: 100 },
];

export const filterOptions = [
  { id: 'experiencedNetBuyersChange', label: 'Experienced Net Buyers Change' },
  { id: 'netBuyersChange', label: 'Net Buyers Change' },
  { id: 'netVolumeChangeUSD', label: 'Buy Pressure Change USD' },
  { id: 'priceChange', label: 'Price' },
];

export const filterOptionsMap: { [key: string]: string } = {
  experiencedNetBuyersChange: 'Experienced Net Buyers',
  netBuyersChange: 'Net Buyers',
  netVolumeChangeUSD: 'Buy Pressure Change USD',
  priceChange: 'Price',
};

export const operatorOptions = [
  [
    { id: 'lt', label: 'Less Than' },
    { id: 'gt', label: 'Greater Than' },
  ],
  [
    { id: 'lt', label: 'Decreased By' },
    { id: 'gt', label: 'Increased By' },
  ],
];

export const timeframeOptions = [
  { id: '10', label: '10m' },
  { id: '30', label: '30m' },
  { id: '60', label: '1H' },
  { id: '240', label: '4H' },
  { id: '720', label: '12H' },
  { id: '1440', label: '1D' },
  { id: '2880', label: '2D' },
  { id: '5760', label: '4D' },
  { id: '10080', label: '1W' },
  { id: '20160', label: '2W' },
  { id: '30240', label: '3W' },
  { id: '43200', label: '1M' },
  { id: '129600', label: '3M' },
];

export const filterTimeframeOptionsMap: { [key: string]: string } = {
  '10': '10m',
  '30': '30m',
  '60': '1H',
  '240': '4H',
  '720': '12H',
  '1440': '1D',
  '2880': '2D',
  '5760': '4D',
  '10080': '1W',
  '20160': '2W',
  '30240': '3W',
  '43200': '1M',
  '129600': '3M',
};

export const coolDownTimeframeOptionsMap: { [key: string]: string } = {
  '0': 'Only one time',
  '5': '5 Seconds',
  '30': '30 Seconds',
  '60': '1 Minute',
  '300': '5 Minutes',
  '600': '10 Minutes',
  '1800': '30 Minutes',
  '3600': '1 Hour',
  '14400': '4 Hours',
  '43200': '12 Hours',
  '86400': 'Day',
};

export const timeframeMap: { [key: string]: string } = {
  '10': 'TenMinutes',
  '30': 'ThirtyMinutes',
  '60': 'OneHour',
  '240': 'FourHours',
  '720': 'TwelveHours',
  '1440': 'OneDay',
  '2880': 'TwoDays',
  '5760': 'FourDays',
  '10080': 'OneWeek',
  '20160': 'TwoWeeks',
  '30240': 'ThreeWeeks',
  '43200': 'OneMonth',
  '129600': 'OneQuarter',
};

export const operatorOptionsMap: { [key: string]: string } = {
  lt: 'Less Than',
  gt: 'Greater Than',
};

export const SUPPORTED_TOKEN_ALERT_CHAINS = [
  Constants.CHAIN_IDS.binance,
  Constants.CHAIN_IDS.ethereum,
  Constants.CHAIN_IDS.polygon,
  Constants.CHAIN_IDS.arbitrum,
];
