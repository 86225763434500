import { FormProvider } from 'react-hook-form';
import { AlertsUsedLabel } from '@/components/common/AlertsUsedLabel';
import { ButtonPrimary } from '@/components/core/Button';
import { useNotify } from '@/helpers/hooks';
import { useAlertModal } from '@/helpers/hooks/useAlertModal';
import { defaultUseFormErrorHandler, useFormHandler } from '@/helpers/hooks/useFormHandler';
import { useMagic } from '@/helpers/hooks/useMagic';
import { useStrategies } from '@/helpers/hooks/useStrategies';
import { useTelegram } from '@/helpers/hooks/useTelegram';
import { useUserAlertsCount } from '@/helpers/hooks/useUserAlertsCount';
import { PutQueryResponseDto } from '@/services/index';
import useUserStore from '@/stores/userStore';
import { AlertScenarioTextVisualization } from '../../atoms';
import { FrequencyField, getNotificationPeriodOptions, NotificationsFieldset, StrategyField } from '../../molecules';
import { CooldownTimeFrameType } from '../../values';
import { IStrategyAlertFormData, IStrategyAlertProps } from './types';
import { getFormDefaultValues, getPayload } from './utils';
import { language, strategyNotificationPeriods } from './values';
import styles from './styles.module.scss';

const StrategyAlert = ({ strategy: strategyToEdit }: IStrategyAlertProps) => {
  const { validateTelegram } = useTelegram();
  const { isLimitReached } = useUserAlertsCount();
  const { close: closeModal } = useAlertModal();
  const { updateStrategy, strategies, messages } = useStrategies();
  const { errorNotification, successNotification } = useNotify();
  const { validateEmail } = useMagic();

  const { isLoading, handleOnSubmit, ...formDefaultMethods } = useFormHandler<
    IStrategyAlertFormData,
    PutQueryResponseDto
  >({
    defaultValues: getFormDefaultValues(strategyToEdit),
    onSubmit: async (formData) => {
      if (!selectedStrategy) throw new Error('Alert not found');
      if (formData.toggleProtocolTelegram) {
        formData.inputProtocolTelegram = await validateTelegram(formData.inputProtocolTelegram);
      }
      if (formData.toggleProtocolEmail) {
        formData.inputProtocolEmail = await validateEmail(formData.inputProtocolEmail);
      }
      const payload = getPayload(formData, selectedStrategy);
      return updateStrategy(payload, {
        onSuccess: (r) => successNotification({ message: messages.update(r.name) }),
      });
    },
    onSuccess: closeModal,
    onError: (error) => defaultUseFormErrorHandler(error, errorNotification),
  });
  const planStage = useUserStore((state) => state?.stage);

  const selectedStrategy =
    strategyToEdit || strategies?.all?.find((strategy) => strategy?.id === formDefaultMethods.watch('selectStrategy'));

  return (
    <FormProvider {...formDefaultMethods}>
      <form className={styles.form} onSubmit={handleOnSubmit}>
        <StrategyField strategies={strategyToEdit ? [strategyToEdit] : strategies?.noAlert} />
        <FrequencyField
          disabled={Boolean(planStage?.notificationPeriods)}
          options={getNotificationPeriodOptions(strategyNotificationPeriods, planStage?.notificationPeriods)}
        />
        <NotificationsFieldset />
        <div className={styles.footer}>
          <AlertScenarioTextVisualization
            alertType="strategy"
            className={styles.scenario}
            cooldownTimeframe={String(+formDefaultMethods.watch('selectCoolDown') * 60) as CooldownTimeFrameType}
            strategyName={selectedStrategy?.name}
          />
          <AlertsUsedLabel />
          <ButtonPrimary
            className={styles.saveButton}
            disabled={isLimitReached}
            isLoading={isLoading}
            size="xl"
            type="submit"
          >
            {language.en.button(Boolean(strategyToEdit))}
          </ButtonPrimary>
        </div>
      </form>
    </FormProvider>
  );
};

export default StrategyAlert;
