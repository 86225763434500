import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input } from '@/components/core/Input';
import { utils } from '@/helpers/utils';
import color from '@/styles/color';
import { Slider } from '@web3uikit/core';
import { ITokenAlertFormData } from '../../organisms/TokenAlert/types';
import { percentages } from '../../values';
import { language } from './values';
import styles from './styles.module.scss';
import typography from '@/styles/scss/typography.module.scss';

interface IPriceInputWithSliderProps {
  price?: number;
}

const PriceInputWithSlider = ({ price }: IPriceInputWithSliderProps) => {
  const { control, setValue } = useFormContext<ITokenAlertFormData>();

  const getTargetPrice = useCallback(
    (changeInPercent: number) => {
      if (price === undefined) return undefined;
      if (changeInPercent < 0) {
        return price * (1 - -changeInPercent / 100);
      } else {
        return price * (1 + changeInPercent / 100);
      }
    },
    [price],
  );

  return (
    <Controller
      control={control}
      name="priceChangePercentage"
      render={({ field: { onChange, onBlur, value, name }, fieldState: { invalid } }) => (
        <div className={styles.wrapper}>
          <Input
            hasError={invalid}
            id={name}
            name={name}
            placeholder={language.en.placeholder}
            type="number"
            value={value}
            onBlur={onBlur}
            onChange={(value) => {
              const valueAsNumber = Number(value.target.value);
              const targetPrice = getTargetPrice(valueAsNumber);
              if (targetPrice !== undefined) {
                setValue('inputValue', targetPrice);
                valueAsNumber < 0 ? setValue('selectOperator', 'lt') : setValue('selectOperator', 'gt');
              }
              onChange(valueAsNumber);
            }}
          />
          <div className={styles.prices}>
            <div className={styles.priceWrapper}>
              <h6 className={typography.bodySmallRegular}>{language.en.current}</h6>
              <span className={typography.bodySmallBold}>{utils.format.formatAsTokenPrice(price)}</span>
            </div>
            <div className={styles.priceWrapper}>
              <h6 className={typography.bodySmallRegular}>{language.en.target}</h6>
              <span className={typography.bodySmallBold}>{utils.format.formatAsTokenPrice(getTargetPrice(value))}</span>
            </div>
          </div>
          <div className={styles.slider}>
            <Slider
              bgColor={color.blue40}
              bgColorTrack={color.moneyAero80}
              boxShadowOfThumb="none"
              id={`${name}-slider`}
              labelBgColor={color.blue40}
              markers={percentages.map((query) => (
                <span key={`step-${query.label}`}>{query.label}</span>
              ))}
              max={100}
              min={-100}
              step={value % 25 === 0 ? 25 : 1}
              value={value}
              onChange={(value) => {
                const valueAsNumber = Number(value);
                const targetPrice = getTargetPrice(valueAsNumber);
                if (targetPrice !== undefined) {
                  setValue('inputValue', targetPrice);
                  valueAsNumber < 0 ? setValue('selectOperator', 'lt') : setValue('selectOperator', 'gt');
                }
                onChange(valueAsNumber);
              }}
            />
          </div>
        </div>
      )}
      rules={{
        required: true,
        validate: (value) => value !== 0 && value > -100,
      }}
    />
  );
};

export default PriceInputWithSlider;
