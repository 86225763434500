import { toError } from './toError';

export type LogLevel = 'error' | 'warn' | 'info' | 'debug';
export interface LogEntry {
  level: LogLevel;
  logName: string;
  message: string;
  vars: LogCtx | null;
  error: Error | undefined;
  context: AppCtxStatic<string>;
  now: number;
}

export type SimpleType = string | number | boolean | undefined | null;
export type LogCtx = object;

export type AppCtxStatic<K extends string> = { appName: string; version: string } & {
  [key in K]: Date | SimpleType | SimpleType[];
};

export type LogHandle = (le: LogEntry) => void;

export class Logger<T extends AppCtxStatic<string> = AppCtxStatic<string>> {
  constructor(private cfg: { app: T; logName: string; send: LogHandle }) {}

  private _send(lvl: LogLevel, msg: string, vars: LogCtx | null, err?: Error) {
    this.cfg.send({
      level: lvl,
      logName: this.cfg.logName,
      message: msg,
      vars,
      error: err,
      context: this.cfg.app,
      now: Date.now(),
    });
  }

  // TODO: add user data for RUM
  // updateContext(ctx: T) {
  //   this.cfg.app = ctx;
  // }
  // public getContext = () => this.cfg.app;

  private createLog(lvl: LogLevel) {
    return (msg: string, vars: LogCtx | null) => this._send(lvl, msg, vars);
  }
  private createLogWithError(lvl: LogLevel) {
    return (msg: string, ex: unknown, vars?: LogCtx) => this._send(lvl, msg, vars ?? null, toError(ex));
  }

  error = this.createLogWithError('error');
  warn = this.createLog('warn');
  warnError = this.createLogWithError('warn');
  notImplemented = this.createLog('warn');
  info = this.createLog('info');
  debug = this.createLog('debug');
}
