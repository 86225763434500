import color from '@/styles/color';
import { faCheckCircle, faExclamationCircle, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNotification } from '@web3uikit/core';

export interface IUseNotify {
  comingSoonNotification(message?: string): void;
  errorNotification(params: INotificationParams): void;
  infoNotification(params: INotificationParams): void;
  successNotification(params: INotificationParams): void;
  warningNotification(params: INotificationParams): void;
}

export interface INotificationParams {
  message: string;
  title?: string;
}

export const useNotify = (): IUseNotify => {
  const pushNotification = useNotification();

  const successNotification = ({ message, title = 'Success' }: INotificationParams) => {
    pushNotification({
      type: 'success',
      title,
      message,
      icon: <FontAwesomeIcon color={color.moneyMint50} icon={faCheckCircle} style={{ width: `16px` }} />,
      iconColor: color.moneyMint50,
      position: 'topR',
    });
  };

  const errorNotification = ({ message, title = 'Something went wrong!' }: INotificationParams) => {
    pushNotification({
      type: 'error',
      title,
      message,
      icon: <FontAwesomeIcon color={color.moneyRed40} icon={faExclamationCircle} style={{ width: `16px` }} />,
      iconColor: color.moneyRed40,
      position: 'topR',
    });
  };

  const warningNotification = ({ message, title = 'Warning' }: INotificationParams) => {
    pushNotification({
      type: 'warning',
      title,
      message,
      icon: <FontAwesomeIcon color={color.moneyYellow50} icon={faInfoCircle} style={{ width: `16px` }} />,
      iconColor: color.moneyYellow50,
      position: 'topR',
    });
  };

  const infoNotification = ({ message, title = 'Something has changed' }: INotificationParams) => {
    pushNotification({
      type: 'info',
      title,
      message,
      icon: <FontAwesomeIcon color={color.moneyNavy40} icon={faInfoCircle} style={{ width: `16px` }} />,
      iconColor: color.moneyNavy40,
      position: 'topR',
    });
  };

  const comingSoonNotification = (message = 'Our mages are hard at work re-vamping this page') => {
    pushNotification({
      type: 'warning',
      title: 'Coming Soon',
      message: message,
      icon: <FontAwesomeIcon color={color.moneyYellow50} icon={faInfoCircle} style={{ width: `16px` }} />,
      iconColor: color.moneyYellow50,
      position: 'topR',
    });
  };

  return {
    successNotification,
    errorNotification,
    comingSoonNotification,
    infoNotification,
    warningNotification,
  };
};
