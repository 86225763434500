import { useEffect } from 'react';
import Script from 'next/script';
import { writeCookieValue } from '@/utils/cookie';

type TapCallback = {
  (err: Error, res: undefined): void;
  (
    err: undefined,
    res: {
      id: string;
      vid: string;
    },
  ): void;
};

type Tap = {
  (evt: 'create', accountId: string, options: { integration: string }): void;
  (evt: 'detect', options: { always_callback: boolean; cookie_domain?: string }, callback: TapCallback): void;
};

declare global {
  interface Window {
    /**
     * @see https://tapfiliate.com/docs/javascript/
     * @see https://tapfiliate.com/docs/integrations/stripe/
     */
    tap?: Tap;
  }
}

const ONE_DAY = 86400000;
// https://moralis.tapfiliate.com/a/settings/program/moralis-money/general/
const COOKIE_TTL = ONE_DAY * 45;

/**
 * Handle tapfiliate library and set necessary cookies
 */
const registerTapfiliate = () => {
  if (window.tap) {
    window.tap('create', '39103-2a3c93', { integration: 'stripe' });
    window.tap('detect', { always_callback: true }, (error, result) => {
      if (error || !result) {
        // error always throws for tap library in initial state
        // so console log was removed
        return;
      }
      writeCookieValue('affiliateM', result.id, COOKIE_TTL);
    });
  }
  const urlParams = new URLSearchParams(window.location.search);
  writeCookieValue('affiliate', urlParams.get('ref'), COOKIE_TTL);
  writeCookieValue('affiliateProgram', urlParams.get('program'), COOKIE_TTL);
  writeCookieValue('affiliateId', urlParams.get('tapid'), COOKIE_TTL);
};

const script = `
(function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){
(t[a].q=t[a].q||[]).push(arguments)}})(window,'tap');
`;

export const Tapfiliate = () => {
  useEffect(() => {
    registerTapfiliate();
  }, []);

  return (
    <>
      <Script async src="https://script.tapfiliate.com/tapfiliate.js" type="text/javascript" />
      <Script dangerouslySetInnerHTML={{ __html: script }} id="tapfiliate-script" />
    </>
  );
};
