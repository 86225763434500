/* eslint-disable @typescript-eslint/no-explicit-any */
export type TCallbacks<T> = {
  onError?: (error: any) => void;
  onFinally?: () => void;
  onSuccess?: (result: T) => void;
};

export async function executeWithCallbacks<T>(fn: () => Promise<T>, callbacks: TCallbacks<T> = {}): Promise<T> {
  try {
    const result = await fn();
    callbacks?.onSuccess?.(result);
    return result;
  } catch (error) {
    callbacks?.onError?.(error);
    throw error;
  } finally {
    callbacks?.onFinally?.();
  }
}
