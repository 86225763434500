import { useMemo } from 'react';
import useSWR from 'swr';
import { api, executeWithCallbacks, TCallbacks } from '@/helpers/utils';
import {
  GetTokenExplorerResponseDto,
  PostTokenExplorerRequestDto,
  PutQueryRequestDto,
  PutQueryResponseDto,
} from '@/services/index';
import useAuthStore from '@/stores/authStore';
import { language } from './values';

const fetchStrategies = async () => {
  return await api.user.userControllerGetStrategy1();
};

export const useStrategies = () => {
  const authToken = useAuthStore((state) => state.authToken);
  const { data, error, mutate, isLoading, isValidating } = useSWR(authToken ? 'get-strategies' : null, fetchStrategies);

  const updateStrategy = async (requestBody: PutQueryRequestDto, callbacks?: TCallbacks<PutQueryResponseDto>) =>
    executeWithCallbacks(async () => {
      const updatedStrategy = await api.user.userControllerUpdateStrategyAlert1(requestBody);
      mutate(
        (prevData) =>
          prevData?.map((oldStrategy) => (oldStrategy.id === updatedStrategy.id ? updatedStrategy : oldStrategy)),
        false,
      );
      return updatedStrategy;
    }, callbacks);

  const manageStrategyStatus = async (
    requestBody: PutQueryRequestDto,
    isPublic: boolean,
    callbacks?: TCallbacks<GetTokenExplorerResponseDto>,
  ) =>
    executeWithCallbacks(async () => {
      const modifiedRequest = {
        ...requestBody,
        isPublic: isPublic,
      };
      const newStrategy = await api.user.userControllerUpdateStrategyAlert1(modifiedRequest);
      mutate(
        (prevData) => prevData?.map((oldStrategy) => (oldStrategy.id === newStrategy.id ? newStrategy : oldStrategy)),
        false,
      );
      return newStrategy;
    }, callbacks);

  const createStrategy = async (
    requestBody: PostTokenExplorerRequestDto,
    callbacks?: TCallbacks<GetTokenExplorerResponseDto>,
  ) =>
    executeWithCallbacks(async () => {
      const newStrategy = (await api.user.userControllerCreateStrategyAlert1(
        requestBody,
      )) as GetTokenExplorerResponseDto;
      mutate((prevData) => [...(prevData || []), newStrategy], false);
      return newStrategy;
    }, callbacks);

  const deleteStrategy = async (strategyId: string, callbacks?: TCallbacks<void>) =>
    executeWithCallbacks(async () => {
      await api.user.userControllerDeleteStrategyAlert1(strategyId);
      mutate((prevData) => prevData?.filter((strategy) => strategy.id !== strategyId), false);
    }, callbacks);

  const toggleStrategy = async (
    strategyId: string,
    enabled: boolean,
    callbacks?: TCallbacks<GetTokenExplorerResponseDto>,
  ) =>
    executeWithCallbacks(async () => {
      let updatedStrategy: GetTokenExplorerResponseDto;
      if (enabled) {
        updatedStrategy = await api.user.userControllerEnableStrategyAlert({
          strategyId,
        });
      } else {
        updatedStrategy = await api.user.userControllerDisableStrategyAlert({
          strategyId,
        });
      }

      mutate(
        (prevData) =>
          prevData?.map((oldStrategy) => (oldStrategy.id === updatedStrategy.id ? updatedStrategy : oldStrategy)),
        false,
      );

      return updatedStrategy;
    }, callbacks);

  const deleteAlertFromStrategy = async (strategyId: string, callbacks?: TCallbacks<GetTokenExplorerResponseDto>) =>
    executeWithCallbacks(async () => {
      const targetStrategy = data?.find((strategy) => strategy.id === strategyId);
      if (!targetStrategy) {
        throw new Error(`Strategy with id ${strategyId} not found`);
      }
      return await updateStrategy({
        ...targetStrategy,
        notificationData: [],
        notification: false,
        monitorTimeInMinutes: undefined,
      });
    }, callbacks);

  const strategies = useMemo(() => {
    if (!data) return;
    const withAlert: GetTokenExplorerResponseDto[] = [];
    const noAlert: GetTokenExplorerResponseDto[] = [];

    for (const strategy of data) {
      if (strategy.notificationData?.length) {
        withAlert.push(strategy);
      } else {
        noAlert.push(strategy);
      }
    }

    return { withAlert, noAlert, all: data };
  }, [data]);

  return {
    messages: language.en,
    strategies,
    isLoading: isLoading || isValidating,
    error,
    createStrategy,
    updateStrategy,
    manageStrategyStatus,
    deleteStrategy,
    toggleStrategy,
    deleteAlertFromStrategy,
  };
};
