import { CHAIN_IDS } from '@/helpers/utils/constants';
import { getKeyByValue } from './getKeyByValue';

/**
 * Convert chain and smart contract address to a DEX Tools link
 */
export const getDEXToolsURL = (chain: string, address: string | undefined) => {
  if (!address) {
    return;
  }
  // Mapping for chain name to coin gecko abbreviation
  const getChainAbbreviation = (chain: string) => {
    const chainAbbr = chain.toLocaleLowerCase();
    switch (chainAbbr) {
      case 'ethereum':
        return 'ether';
      case 'binance':
        return 'bnb';
      case 'pulsechain':
        return 'pulse';
      default:
        return chainAbbr;
    }
  };
  return `https://www.dextools.io/app/en/${getChainAbbreviation(chain)}/pair-explorer/${address}`;
};

/**
 * FIXME: improve the interface for the funciton and coinLogo.ts
 * @deprecated
 */
export const getCoinLogoURL = (chain: string, address: string, dexToolLogo?: string, isLogoAvailable = 0) => {
  const usp = new URLSearchParams();
  usp.set('chain', chain);
  if (address) {
    usp.set('address', address);
  }
  if (dexToolLogo) {
    usp.set('dexToolLogo', dexToolLogo);
  }
  usp.set('isLogoAvailable', isLogoAvailable.toString());
  return `/api/coinLogo?${usp.toString()}`;
};

export const getTokenURLByAddress = ({ chainName, address }: { chainName: string; address: string }) =>
  `/chain/${chainName}/token/price/${address}`;

export const getChainNameById = (chainId: string) => getKeyByValue(CHAIN_IDS, chainId);

export const getTokenURLById = ({
  tokenAddress,
  chainId,
  uiniqueName,
}: {
  chainId: string;
  tokenAddress: string;
  uiniqueName?: string | null;
}) =>
  getTokenURLByAddress({
    chainName: getChainNameById(chainId) ?? '',
    address: uiniqueName || tokenAddress,
  });

export const getCoinURLById = (coin: { chainId: string; coinAddress: string; uniqueName?: string | null }) =>
  getTokenURLById({
    chainId: coin.chainId,
    tokenAddress: coin.coinAddress,
    uiniqueName: coin.uniqueName,
  });
