import color from '@/styles/color';
import { HexToRgb } from '@web3uikit/styles';

const fontUItemp = 'IBM Plex Sans';

const styleHelpers = {
  //Border Radius
  borderRadius: '12px',
  borderRadiusCircle: '50%',
  borderRadiusMax: '300px',
  borderRadiusMedium: '8px',
  borderRadiusSmall: '4px',

  //Box Shadows
  boxShadow: `0px 0 30px rgba(${HexToRgb(color.moneyAero90)}, 0.7)`,
  boxShadowBottom: `0px 20px 30px rgba(${HexToRgb(color.moneyAero90)}, 0.7)`,

  //Colors
  modalOverlay: `rgba(${HexToRgb(color.moneyAero90)}, 0.8)`,

  //Transitions
  transition: 'all 0.1s ease',

  //Fonts
  fontUIRegular: fontUItemp,
  fontUIMedium: fontUItemp,
  fontUIBold: fontUItemp,

  //Menus
  mobileMenuHeight: '85px',
} as const;

export default styleHelpers;
