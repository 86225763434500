import { useMemo } from 'react';
import useSWR from 'swr';
import { api, executeWithCallbacks, TCallbacks } from '@/helpers/utils';
import {
  FollowCuratedWalletListRequest,
  GetAllCuratedWalletListResponse,
  GetCuratedWalletListResponse,
  UnFollowCuratedWalletListRequest,
} from '@/services/index';
import useAuthStore from '@/stores/authStore';
import { language } from './values';

const fetchBundles = async () => {
  return await api.user.userControllerGetCuratedWalletLists1();
};

export const useBundles = () => {
  const authToken = useAuthStore((state) => state.authToken);
  const { data, error, mutate, isLoading, isValidating } = useSWR(authToken ? 'get-bundles' : null, fetchBundles);

  const followBundle = async (
    requestBody: FollowCuratedWalletListRequest,
    callbacks?: TCallbacks<GetCuratedWalletListResponse>,
  ) =>
    executeWithCallbacks(async () => {
      const updatedAlert = await api.user.userControllerFollowCuratedWalletList(requestBody);
      mutate(
        (prevData) => prevData?.map((oldAlert) => (oldAlert.id === updatedAlert.id ? updatedAlert : oldAlert)),
        false,
      );
      return updatedAlert;
    }, callbacks);

  const unfollowBundle = async (
    requestBody: UnFollowCuratedWalletListRequest,
    callbacks?: TCallbacks<GetCuratedWalletListResponse>,
  ) =>
    executeWithCallbacks(async () => {
      const updatedAlert = await api.user.userControllerUnfollowCuratedWalletList(requestBody);
      mutate(
        (prevData) => prevData?.map((oldAlert) => (oldAlert.id === updatedAlert.id ? updatedAlert : oldAlert)),
        false,
      );
      return updatedAlert;
    }, callbacks);

  const bundles = useMemo(() => {
    if (!data) return;

    const activeBundles = data.filter((bundle) => bundle.status === 'active');

    const withAlert: GetAllCuratedWalletListResponse[] = [];
    const noAlert: GetAllCuratedWalletListResponse[] = [];

    for (const bundle of activeBundles || []) {
      if (bundle.isFollowing) {
        withAlert.push(bundle);
      } else {
        noAlert.push(bundle);
      }
    }
    return { withAlert, noAlert, all: activeBundles };
  }, [data]);

  return {
    messages: language.en,
    bundles,
    isLoading: isLoading || isValidating,
    error,
    followBundle,
    unfollowBundle,
  };
};
