import { useMemo } from 'react';
import { ApiError } from '@/services/index';
import useUserStore from '@/stores/userStore';
import { EProtocolType } from '@/types/validatedProtocol';
import { DisplayableError } from '@/utils/DisplayableError';
import { createMagic, loginWithEmailOTP } from '@/utils/magic';
import { api, Constants } from '../utils';

export const useMagic = () => {
  const { userValidatedProtocolsEmail, userEmail } = useUserStore((state) => {
    return {
      userValidatedProtocolsEmail: state.user?.validatedProtocols?.find(
        (protocol) => protocol.type === EProtocolType.EMAIL,
      ),
      userEmail: state.user?.email,
    };
  });

  const magic = useMemo(createMagic, []);

  const addEmail = async (newEmailToken: string) =>
    api.user.userControllerAddEmail({
      token: newEmailToken,
    });

  const sendMagicLink = async (email: string) => {
    try {
      const newEmailToken = await loginWithEmailOTP(magic, email);
      if (!newEmailToken) {
        throw new DisplayableError('Error processing the Magic code.', 'Please check your email and retry');
      }
      await addEmail(newEmailToken);
      return newEmailToken;
    } catch (error) {
      let errorMessage = Constants.errorFeedback;
      if (error instanceof ApiError) {
        errorMessage = error.body?.cause as string;
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }

      throw new DisplayableError('Email Authentication Failed', errorMessage);
    }
  };

  const validateEmail = async (email: string) => {
    const emailValidated = userValidatedProtocolsEmail?.data.includes(email);
    if (!emailValidated && email !== userEmail) {
      return sendMagicLink(email);
    }
    return email;
  };

  return { sendMagicLink, validateEmail };
};
