import { api } from '@/helpers/utils';
import { GetWalletsNetWorthResponseDto } from '@/services/index';
import { logApp } from '@/utils/logApp';
import { SUPPORTED_CHAINS } from '@/utils/supportedChains';

const log = logApp.create('getWalletNetWorth');

export const getWalletNetWorth = async (addresses: Array<string>) => {
  return await api.web3
    .web3ControllerGetWalletsNetWorth({
      chainIds: SUPPORTED_CHAINS,
      addressesOrNames: addresses,
    })
    .then((res) => res as unknown as GetWalletsNetWorthResponseDto)
    .catch((error) => {
      log.warnError('[getWalletNetWorth]', error, { addresses });
      return {
        total_networth_usd: 0,
      } as unknown as GetWalletsNetWorthResponseDto;
    });
};
