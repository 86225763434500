import { Constants } from '@/helpers/utils';
import { GetAllCuratedWalletListResponse, INotificationData } from '@/services/index';
import useUserStore from '@/stores/userStore';
import { EProtocolType } from '@/types/validatedProtocol';
import { normalizeTelegramUsername } from '../../utils';
import { IBundleAlertFormData } from './types';

export const getFormDefaultValues = (bundle?: GetAllCuratedWalletListResponse): IBundleAlertFormData => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { userEmail, userValidatedProtocolsTelegram } = useUserStore((state) => {
    return {
      userValidatedProtocolsTelegram: state.user?.validatedProtocols?.filter(
        (protocol) => protocol.type === EProtocolType.TELEGRAM,
      )[0],
      userEmail: state.user?.email,
    };
  });

  return {
    selectBundle: bundle?.id || '',
    inputProtocolEmail: userEmail || '',
    inputProtocolTelegram: normalizeTelegramUsername(userValidatedProtocolsTelegram?.data[0]) || '',
    inputProtocolWebhook: '',
    toggleProtocolEmail: true,
    toggleProtocolTelegram: false,
    toggleProtocolWebhook: false,
  };
};

export const getPayload = (data: IBundleAlertFormData) => {
  const notificationData: INotificationData[] = [];
  if (data?.toggleProtocolTelegram && data?.inputProtocolTelegram) {
    notificationData.push({
      type: Constants.notificationTypeTelegram,
      data: data.inputProtocolTelegram,
    });
  }
  if (data?.toggleProtocolWebhook && data?.inputProtocolWebhook) {
    notificationData.push({
      type: Constants.notificationTypeWebhook,
      data: data.inputProtocolWebhook,
    });
  }
  if (data?.toggleProtocolEmail && data?.inputProtocolEmail) {
    notificationData.push({
      type: Constants.notificationTypeEmail,
      data: data.inputProtocolEmail,
    });
  }

  return {
    id: data?.selectBundle,
    notificationData,
  };
};
