import { useMemo } from 'react';
import { utils } from '@/helpers/utils';

interface IUseFormattedMetricProps {
  isChangeable?: boolean;
  maximumFractionDigits?: number;
  metricType: 'percent' | 'currency' | 'number';
  minimumFractionDigits?: number;
  value?: number;
}

const useFormattedMetric = ({
  value,
  metricType,
  isChangeable = false,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
}: IUseFormattedMetricProps) => {
  const isPositive = useMemo(() => {
    return value && value >= 0 ? true : false;
  }, [value]);

  const metricValue = useMemo(() => {
    if (value === undefined || value === null) {
      return 'N/A';
    }

    let sign = '';

    if (isChangeable && isPositive) {
      sign = '+';
    }

    switch (metricType) {
      case 'currency':
        return sign + utils.format.formatAsCurrency(value);
      case 'percent':
        return sign + utils.format.formattedNumber(value, minimumFractionDigits, maximumFractionDigits) + '%';
      case 'number':
        return sign + utils.format.formattedNumber(value, minimumFractionDigits, maximumFractionDigits);
    }
  }, [metricType, value, isChangeable, isPositive]);

  return { metricValue, isPositive };
};

export default useFormattedMetric;
