import Big from 'big.js';
import { TRichContent } from '@/types/nftRichContent';
import { isNullish } from '@/utils/isNullish';
import { sha } from '@/utils/sha';
import { numberConverter } from './numberConverter';
class Format {
  /**
   * Responsible for converting number to comma separated number string
   */
  public formattedNumber = (n: number | string, minimumFractionDigits = 2, maximumFractionDigits = 18) => {
    const number = Number(n);

    // Define thresholds for Peta, Exa, Zetta, and Yotta
    const PET = 1e15; // Peta (P)
    const EXA = 1e18; // Exa (E)
    const ZET = 1e21; // Zetta (Z)
    const YOT = 1e24; // Yotta (Y)

    if (number >= 1000 || number <= -1000) {
      // Extend to handle larger values with custom prefixes
      const absNumber = Math.abs(number);
      if (absNumber >= YOT) {
        return Math.round(number / YOT) + 'Y';
      } else if (absNumber >= ZET) {
        return Math.round(number / ZET) + 'Z';
      } else if (absNumber >= EXA) {
        return Math.round(number / EXA) + 'E';
      } else if (absNumber >= PET) {
        return Math.round(number / PET) + 'P';
      } else {
        // Use compact notation for numbers less than Peta
        return Intl.NumberFormat('en-US', {
          notation: 'compact',
          maximumFractionDigits: 1,
        }).format(number);
      }
    }

    // Return standard localized number format for numbers smaller than 1000
    return number.toLocaleString(undefined, {
      minimumFractionDigits,
      maximumFractionDigits,
    });
  };

  /**
   * Responsible for converting number to Currency Format
   */
  public formatAsCurrency = (
    amount: number | undefined,
    numCurrency = 'USD',
    numFormat = 'en-US',
    maximumFractionDigits = 1,
    minimumFractionDigits = 0,
  ) => {
    const number = Number(amount);

    if (number >= 1000 || number <= -1000) {
      return Intl.NumberFormat(numFormat, {
        currency: numCurrency,
        maximumFractionDigits,
        minimumFractionDigits,
        notation: 'compact',
        style: 'currency',
      }).format(number);
    }
    const formatter = new Intl.NumberFormat(numFormat, {
      style: 'currency',
      currency: numCurrency,
    });

    return formatter.format(Number(amount));
  };

  /**
   * Responsible for converting number to Currency Format
   * but for numbers we do not want to round up or down
   */
  public formatAsCurrencyWithoutRounding = (
    amount: number | undefined,
    numCurrency = 'USD',
    numFormat = 'en-US',
    maximumFractionDigits = 0,
  ) => {
    const formatter = new Intl.NumberFormat(numFormat, {
      style: 'currency',
      currency: numCurrency,
      minimumFractionDigits: maximumFractionDigits,
      maximumFractionDigits: maximumFractionDigits,
    });

    return formatter.format(Number(amount));
  };

  /**
   * Responsible for Calculating the dollar value of a token
   */
  public getDollarValue = (
    balance: string, // Amount of tokens
    current_value: number, // Current value of the token
    decimals: number, // Decimals of the token
  ): number => {
    if (current_value && current_value != undefined) {
      return Number(
        Big(Number(balance))
          .div(10 ** Number(decimals))
          .mul(Number(current_value))
          .toNumber()
          .toFixed(2),
      );
    }
    return 0;
  };

  /**
   * Responsible for Truncating given string if it's longer then the passed length
   */
  public truncate = (str: string, length = 14) => {
    if (str.length <= length) return str;
    const separator = '...';
    const sepLen = separator.length,
      charsToShow = length - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2);

    return str.substring(0, frontChars) + separator + str.substring(str.length - backChars);
  };

  /**
   * Responsible for Truncating given string if it's longer then the passed length, only the last characters are cut off
   */

  public truncateRemoveLast = (str: string, length = 12) => {
    if (str.length <= length) return str;
    const separator = '...';
    const sepLen = separator.length;
    const charsToShow = length - sepLen;

    if (charsToShow <= 0) {
      return str.slice(0, length);
    }

    const frontChars = charsToShow;
    return str.slice(0, frontChars) + separator;
  };

  /**
   * Responsible for decoding the NFT metadata and returning the correct image types
   */
  public nftToImageFormatDecoder = (
    metaData?: Record<string, unknown>,
  ): {
    imgUrl: string;
    richContentType?: TRichContent;
  } => {
    if (!metaData) {
      return { imgUrl: '' };
    }

    const base64Check = 'data:image/png;base64';
    const ipfsCheck = 'ipfs://';
    const ipfsUrl = 'https://ipfs.io/ipfs/';
    const iFrameUrl = '.lens';

    const url = String(metaData.image || metaData.image_url || metaData.animation_url);
    switch (true) {
      case url.startsWith(base64Check):
        return { imgUrl: url, richContentType: 'Base64' };
      case url.endsWith(iFrameUrl):
        return { imgUrl: '', richContentType: 'iFrame' };
      case metaData.mainContentFocus === 'VIDEO':
        return { imgUrl: '', richContentType: 'Video' };
      case metaData.mainContentFocus === 'AUDIO':
        return { imgUrl: '', richContentType: 'Audio' };
      case url.startsWith(ipfsCheck):
        return { imgUrl: url.replace(ipfsCheck, ipfsUrl) };
    }
    return { imgUrl: url };
  };

  /**
   * Responsible for converting number to token price format
   * If has more than 2 zeros after decimal point, convert to subscript
   */
  public formatAsTokenPrice = (num?: number): string => {
    if (num == undefined) return 'N/A';

    const leadingZerosCount = numberConverter.getZerosAmountAfterPoint(num);

    const shouldBeSubscript = leadingZerosCount >= 2 && num < 1;

    if (shouldBeSubscript) {
      return `$${numberConverter.convertToSubscript(num, leadingZerosCount, 5)}`;
    }

    // If the number is less than 1, show 4 decimal places else show 2
    const maximumFractionDigits = num < 1 ? 4 : 2;
    return this.formatAsCurrencyWithoutRounding(num, undefined, undefined, maximumFractionDigits);
  };

  /**
   * Responsible for capitalizing the first letter of a given string
   */
  public capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  /**
   * Responsible for capitalizing the first letter of a given string
   */
  public capitalizeHyphenSeparatedWords = (str: string) => {
    // Split the string by hyphen
    const parts = str.split('-');

    // Capitalize each part using the provided utility and reassemble
    const capitalizedParts = parts.map((part) => {
      // Assuming utils.format.capitalize exists and capitalizes the first letter of the string
      // If it capitalizes the whole string or behaves differently, you might need to adjust this line
      return this.capitalize(part);
    });

    // Join the capitalized parts back with a hyphen
    return capitalizedParts.join('-');
  };

  /**
   * Responsible for converting metric number t o formatted value
   */
  public getFormattedMetric = (
    metricValue: number,
    metricType: 'percent' | 'currency' | 'number' = 'number',
    isChangeable = false,
    isVerbose = false,
    noMetricValue = 'N/A',
  ) => {
    const isPositive = metricValue && metricValue >= 0 ? true : false;

    if (!metricValue) {
      return { value: noMetricValue, isPositive };
    }

    let sign = '';

    if (isChangeable) {
      if (isPositive) {
        sign = isVerbose ? 'increased by ' : '+';
      } else {
        // Bill removed the sign as this function is only being used in one place currently
        // and it renders twice as the data has the minus symbol already. returns: --5%
        sign = isVerbose ? 'decreased by ' : '';
      }
    }

    let value;

    switch (metricType) {
      case 'currency':
        value = sign + this.formatAsCurrency(metricValue);
        break;
      case 'percent':
        value = sign + this.formattedNumber(metricValue, 2, 2) + '%';
        break;
      case 'number':
        value = sign + metricValue.toFixed(12).replace(/\.?0+$/, '');
        break;
      default:
        value = 'N/A';
        break;
    }

    return { value, isPositive };
  };

  // makes ID string human readable label
  public splitCamelCase = (str: string): string => {
    return str.replace(/([A-Z])/g, ' $1').trim();
  };

  public getValueOrNA(value?: string | number): string | number {
    return value !== undefined && value !== null && value !== '' ? value : 'N/A';
  }

  /**
   * Handles an address, string of addresses, or null.
   * - Returns the string as-is if addresses is a string.
   * - Returns the first element or joins all with a dash if it's an array.
   * - Returns null if addresses is null.
   * @param {string|string[]|null} addresses - The addresses to process.
   * @param {boolean} joinWithDash - Indicates whether to join array elements with a dash.
   * @returns {string} - The processed addresses as a string. Returns empty string if addresses is null.
   */
  public addressesToString(addresses: string | string[] | null, joinWithDash: boolean = false): string {
    if (typeof addresses === 'string') {
      return addresses;
    } else if (Array.isArray(addresses)) {
      return joinWithDash ? sha(addresses.sort().join('-')) : addresses[0];
    }
    return '';
  }

  /**
   * Handles an address, string of addresses, or null.
   * - Returns as-is if addresses is a array.
   * - Returns a string with the address as the only element if it's a string.
   * - Returns an empty array if addresses is null.
   * @param {string|string[]|null} addresses - The addresses to process.
   * @returns {string} - The processed addresses as aan array of strings. Returns empty string[] if addresses is null.
   */
  public addressesToArray(addresses: string | string[] | null): string[] {
    if (isNullish(addresses)) return [];
    return Array.isArray(addresses) ? addresses : [addresses];
  }
}

const format = new Format();
export default format;
