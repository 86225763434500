import { useEffect } from 'react';
import { useAccount } from 'wagmi';
import { api } from '@/helpers/utils';
import useAuthStore from '@/stores/authStore';
import { decodeJwtToken } from '@/utils/jwt';
import { logApp } from '@/utils/logApp';
import { addWalletToPortfolio } from '../libs/addWallet';
import { EAdditionMethod } from '../libs/types';
import { useMultiWalletData } from './useMultiWalletData';

/**
 * Checks if a connected wallet is already stored in the user lists. If not adds it.
 */

const log = logApp.create('useAddConnectedWallet');

export const useAddConnectedWallet = () => {
  const authToken = useAuthStore((state) => state.authToken);
  const { address, connector } = useAccount();
  const { mutate } = useMultiWalletData();

  useEffect(() => {
    if (!authToken || !address) {
      return;
    }

    const tokenDecoded = decodeJwtToken(authToken);
    const walletLinkedToMM: string = tokenDecoded.decodedPayload.address;

    // If the connected account is the same as the one linked to the user money profile, return
    if (walletLinkedToMM && walletLinkedToMM.toLowerCase() === address.toLowerCase()) {
      return;
    }

    const connectorName = connector?.name || EAdditionMethod.WATCHED;

    api.user.userControllerGetUserPortfolioWallets().then((res) => {
      // Check if the address is already included in a list
      const found = res.wallets.find((wallet) => wallet.address.toLowerCase() === address.toLowerCase());

      if (found) {
        return;
      }

      // Add the wallet to the portfolio list
      addWalletToPortfolio(address, connectorName as EAdditionMethod)
        .then(() => {
          mutate();
        })
        .catch((err) => {
          log.warnError('[addWalletToPortfolio]', err, { address });
        });
    });
  }, [authToken, address]);
};
