'use client';
import { ButtonPrimary } from 'moralis-ui';
import { Constants } from '@/helpers/utils';
import { faRocket } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { language } from './values';

const UpgradeProLink = () => (
  <ButtonPrimary
    onClick={() => {
      window.open(Constants.ERoutePath.pricing, '_self');
    }}
  >
    <FontAwesomeIcon icon={faRocket} />
    {language.en.buttonText}
  </ButtonPrimary>
);

export default UpgradeProLink;
