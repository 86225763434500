import { Constants } from '@/helpers/utils';
import { GetTokenExplorerResponseDto, INotificationData, PutQueryRequestDto } from '@/services/index';
import useUserStore from '@/stores/userStore';
import { EProtocolType } from '@/types/validatedProtocol';
import { normalizeTelegramUsername } from '../../utils';
import { IStrategyAlertFormData } from './types';

export const getFormDefaultValues = (query?: GetTokenExplorerResponseDto): IStrategyAlertFormData => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { userEmail, userValidatedProtocolsTelegram } = useUserStore((state) => {
    return {
      userValidatedProtocolsTelegram: state.user?.validatedProtocols?.filter(
        (protocol) => protocol.type === EProtocolType.TELEGRAM,
      )[0],
      userEmail: state.user?.email,
    };
  });

  const serializedNotificationData: Partial<Record<EProtocolType, string>> = query?.notificationData
    ? Object.fromEntries(query.notificationData?.map((item) => [item.type, item.data]))
    : {};

  return {
    inputProtocolEmail: serializedNotificationData?.email || userEmail || '',
    inputProtocolTelegram:
      normalizeTelegramUsername(serializedNotificationData?.telegram || userValidatedProtocolsTelegram?.data[0]) || '',
    inputProtocolWebhook: serializedNotificationData?.webhook || '',
    selectCoolDown: query?.monitorTimeInMinutes?.toString() || '',
    selectStrategy: query?.id || '',
    toggleProtocolEmail: Boolean(serializedNotificationData?.email),
    toggleProtocolTelegram: Boolean(serializedNotificationData?.telegram),
    toggleProtocolWebhook: Boolean(serializedNotificationData?.webhook),
  };
};

export const getPayload = (data: IStrategyAlertFormData, query: GetTokenExplorerResponseDto): PutQueryRequestDto => {
  const notificationData: INotificationData[] = [];
  if (data?.toggleProtocolTelegram && data?.inputProtocolTelegram) {
    notificationData.push({
      type: Constants.notificationTypeTelegram,
      data: data.inputProtocolTelegram,
    });
  }

  if (data?.toggleProtocolWebhook && data?.inputProtocolWebhook) {
    notificationData.push({
      type: Constants.notificationTypeWebhook,
      data: data.inputProtocolWebhook,
    });
  }

  if (data?.toggleProtocolEmail && data?.inputProtocolEmail) {
    notificationData.push({
      type: Constants.notificationTypeEmail,
      data: data.inputProtocolEmail,
    });
  }

  return {
    name: query.name,
    chainId: query.chainId,
    queries: query.queries,
    notification: true,
    monitorTimeInMinutes: Number(data.selectCoolDown),
    notificationData,
    id: query.id,
    email: data?.inputProtocolEmail,
  };
};
