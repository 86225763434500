const throwResp = (response: Response) => {
  throw new Error(`${response.status} ${response.statusText}. Failed to POST "${response.url}"`);
};

export const postJSON = async (
  url: string,
  {
    body,
    authToken,
    onNotOK = throwResp,
    initParams,
  }: {
    authToken?: string;
    body: unknown;
    onNotOK?: <T>(response: Response) => T;
    initParams?: RequestInit;
  },
) => {
  const response = await fetch(url, {
    ...initParams,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'Authorization': !authToken ? '' : `Bearer ${authToken}`,
    },
    body: body ? JSON.stringify(body) : undefined,
  });
  if (!response.ok) {
    return onNotOK(response);
  }
  const json = await response.json();
  return json as unknown;
};
