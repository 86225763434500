import { chainOptions } from '@/helpers/utils/chainSelectorOptions';
import { Constants } from '../helpers';

/**
 * @description Filters out unsupported chains to prevent breaking some endpoint like the get wallet net worth.
 * @constant TEMPORARY_UNSUPPORTED_CHAINS - Chains not supported due to incidents or any other issues
 * @constant UNSUPPORTED_CHAINS - Chains not supported
 * @constant SUPPORTED_CHAINS - An array of supported chains
 * @function isChainSupported - Checks if a chain is supported
 */

// @note - added fantom chain because of incident https://moralisweb3.slack.com/archives/C07BPBFKML5
const TEMPORARY_UNSUPPORTED_CHAINS = [Constants.CHAIN_IDS.fantom];

const UNSUPPORTED_CHAINS = [
  Constants.CHAIN_IDS.pulse,
  Constants.CHAIN_IDS.linea,
  Constants.CHAIN_IDS.solana,
  ...TEMPORARY_UNSUPPORTED_CHAINS,
];

export const isChainSupported = (chain: string) => !UNSUPPORTED_CHAINS.includes(chain);

export const SUPPORTED_CHAINS = chainOptions.filter((chain) => isChainSupported(chain.id)).map((chain) => chain.id);
