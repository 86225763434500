import { ChainLogo } from '@/components/common/ChainLogo';
import { CHAIN_IDS } from './constants';

export const SUPPORTED_CHAIN_IDS = [1, 56, 137, 43114, 250, 100, 10, 8453, 324, 42161];

export type ChainOption = {
  label: string;
  prefix: JSX.Element;
  id: string;
  disabled: boolean;
  minimumPlanLvlRequired: number | null;
};

export const chainOptions: ChainOption[] = [
  {
    label: 'Ethereum',
    prefix: <ChainLogo chainId={CHAIN_IDS.ethereum} />,
    id: CHAIN_IDS.ethereum,
    disabled: false,
    minimumPlanLvlRequired: null, // note - null means public(non logged in) users can use it as well
  },
  {
    label: 'Binance',
    prefix: <ChainLogo chainId={CHAIN_IDS.binance} />,
    id: CHAIN_IDS.binance,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  {
    label: 'Polygon',
    prefix: <ChainLogo chainId={CHAIN_IDS.polygon} />,
    id: CHAIN_IDS.polygon,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  {
    label: 'Arbitrum',
    prefix: <ChainLogo chainId={CHAIN_IDS.arbitrum} />,
    id: CHAIN_IDS.arbitrum,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  {
    label: 'Fantom',
    prefix: <ChainLogo chainId={CHAIN_IDS.fantom} />,
    id: CHAIN_IDS.fantom,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  {
    label: 'Optimism',
    prefix: <ChainLogo chainId={CHAIN_IDS.optimism} />,
    id: CHAIN_IDS.optimism,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  {
    label: 'PulseChain',
    prefix: <ChainLogo chainId={CHAIN_IDS.pulse} />,
    id: CHAIN_IDS.pulse,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  {
    label: 'Avalanche',
    prefix: <ChainLogo chainId={CHAIN_IDS.avalanche} />,
    id: CHAIN_IDS.avalanche,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  {
    label: 'Base',
    prefix: <ChainLogo chainId={CHAIN_IDS.base} />,
    id: CHAIN_IDS.base,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  {
    label: 'Linea',
    prefix: <ChainLogo chainId={CHAIN_IDS.linea} />,
    id: CHAIN_IDS.linea,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  {
    label: 'Solana',
    prefix: <ChainLogo chainId={CHAIN_IDS.solana} />,
    id: CHAIN_IDS.solana,
    disabled: false,
    minimumPlanLvlRequired: 40,
  },
];

export const supportedTradeChainOptions = chainOptions.filter((option) => {
  if (option.id == CHAIN_IDS.solana) return false;
  return SUPPORTED_CHAIN_IDS.includes(parseInt(option.id));
});
