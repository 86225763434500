import { useCallback } from 'react';
import useSWR from 'swr';
import { Coin, UserAddCoinFavouritesRequestDto, UserCoinFavouritesResponseDto } from '@/services/index';
import { logApp } from '@/utils/logApp';
import { api } from '../utils';

interface IRemoveCoinFavourite {
  address: string;
  chainId: string;
}

const log = logApp.create('useUserCoinFavourites');

const fetchUserCoinFavourites = async () => {
  const response = await api.user.userControllerGetAllUserCoinFavourites();
  return response as unknown as UserCoinFavouritesResponseDto[];
};

export const useUserCoinFavourites = () => {
  const {
    data: coinFavourites,
    mutate: mutateCoinFavourites,
    isValidating: isCoinFavouritesValidating,
    isLoading: isCoinFavouritesLoading,
  } = useSWR('coinFavourites', fetchUserCoinFavourites, {
    revalidateOnFocus: false,
  });

  const {
    data: coinFavouritesData,
    mutate: mutateTokenData,
    isValidating: isFetching,
    isLoading,
  } = useSWR(
    ['tokenData', coinFavourites],
    !coinFavourites
      ? null
      : async () =>
          await api.insights.hiddenGemCoinsSearch({
            coinList: coinFavourites.map((coin) => ({
              chainId: coin.chainId,
              coinTokenAddress: coin.tokenAddress,
            })),
          }),
    {
      revalidateOnFocus: false,
    },
  );

  const addCoinFavourite = async (request: UserAddCoinFavouritesRequestDto) => {
    try {
      await api.user.userControllerAddUserCoinFavourite(request);
      mutateCoinFavourites();
    } catch (error) {
      log.error('Error adding coin favourite', error, request);
    }
  };

  const removeCoinFavourite = useCallback(
    async ({ address, chainId }: IRemoveCoinFavourite) => {
      if (!coinFavourites) return;
      try {
        const { id } = coinFavourites.find((c) => {
          return c.chainId === chainId && c.tokenAddress === address;
        }) as UserCoinFavouritesResponseDto;
        await api.user.userControllerRemoveUserCoinFavourite(id);
        mutateCoinFavourites((data: UserCoinFavouritesResponseDto[] | undefined) => {
          return data?.filter((coin) => {
            return coin.id !== id;
          });
        });
        mutateTokenData((data: Coin[] | undefined) => {
          return data?.filter((coin) => {
            return coin.chainId_coinTokenAddress !== `${chainId}_${address}`;
          });
        });
      } catch (error) {
        log.error('Error removing coin favourite', error, { address, chainId });
      }
    },
    [coinFavourites, mutateCoinFavourites, mutateTokenData],
  );

  return {
    coinFavourites,
    coinFavouritesData,
    addCoinFavourite,
    removeCoinFavourite,
    isFetching: isFetching || isLoading || isCoinFavouritesValidating || isCoinFavouritesLoading,
  };
};
