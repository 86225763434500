import React from 'react';
import { getChainIconComponent } from '@/assets/img/chains';
import { Constants } from '@/helpers/utils';
import styles from './ChainLogo.module.scss';
const { getCryptoLogoProps, chainIdToChainNameMapping } = Constants;

interface IChainLogoProps {
  chainId: string;
  className?: string;
  transparent?: boolean;
}

const ChainLogo: React.FC<IChainLogoProps> = ({ chainId, className, transparent = false }) => {
  const IconComponent = getChainIconComponent(chainId);

  return (
    <IconComponent
      className={`${styles.logo} ${className} ignore-global-styles`}
      style={transparent ? undefined : { background: getCryptoLogoProps(chainIdToChainNameMapping(chainId)).bgColor }}
    />
  );
};

export default React.memo(ChainLogo);
