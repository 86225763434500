import { Constants } from '@/helpers/utils';
import AllChains from './AllChains';
import Arbitrum from './Arbitrum';
import Avalanche from './Avalanche';
import Base from './Base';
import BNB from './BNB';
import Cronos from './Cronos';
import Ethereum from './Ethereum';
import Fantom from './Fantom';
import Linea from './Linea';
import Optimism from './Optimism';
import Polygon from './Polygon';
import Pulsechain from './Pulsechain';
import Solana from './Solana';

export function getChainIconComponent(chainId: string) {
  switch (chainId) {
    case 'allChains':
      return AllChains;
    case Constants.CHAIN_IDS.arbitrum:
      return Arbitrum;
    case Constants.CHAIN_IDS.avalanche:
      return Avalanche;
    case Constants.CHAIN_IDS.binance:
      return BNB;
    case Constants.CHAIN_IDS.cronos:
      return Cronos;
    case Constants.CHAIN_IDS.fantom:
      return Fantom;
    case Constants.CHAIN_IDS.polygon:
      return Polygon;
    case Constants.CHAIN_IDS.pulse:
      return Pulsechain;
    case Constants.CHAIN_IDS.optimism:
      return Optimism;
    case Constants.CHAIN_IDS.base:
      return Base;
    case Constants.CHAIN_IDS.linea:
      return Linea;
    case Constants.CHAIN_IDS.solana:
      return Solana;
    case Constants.CHAIN_IDS.ethereum:
    default:
      return Ethereum;
  }
}
